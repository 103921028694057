import React, { useEffect, useState } from "react";
import { trackEvent } from "@leafly-com/web-utils";
import classnames from "classnames";
import { useSelector } from "react-redux";

import { Action, Category, Label } from "constants/events";
import { MenuItem } from "custom-types/MenuItem";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import { useEventTracker } from "hooks/useEventTracker";
import { getCartSessionId } from "redux/selectors/cart";
import { getUserPrefersMedical } from "redux/selectors/user";
import isDispensaryDualLicense from "utils/dispensary/isDispensaryDualLicense";
import logError from "utils/logError";
import { MenuType, testVariantsForMenuType } from "utils/menuTypeUtils";

import Button from "components/botanic/Button";
import Image from "components/Image";
import MenuTypeSelection from "components/Shared/MenuTypeSelection";
import SmartOfferCard from "components/SmartOfferCard";

import { sortVariants } from "./utils/variant";
import { VariantTile } from "./VariantTile";

type VariantSelectionProps = {
  menuItem: MenuItem;
  onAddToBagClick: () => Promise<void>;
  onMenuTypeChange?: () => void;
  onVariantSelect: (id: number) => void;
  selectedVariantId: number;
};

export const VariantSelection: React.FC<VariantSelectionProps> = ({
  menuItem,
  onAddToBagClick,
  onMenuTypeChange,
  onVariantSelect,
  selectedVariantId,
}) => {
  const cartSessionId = useSelector(getCartSessionId);
  const countryCode = useDomainCountryCode();

  const [addingToBag, setAddingToBag] = useState(false);
  const [selectionView, setSelectionView] = useState<"variants" | "offers">(
    "variants",
  );
  const { publishEvent } = useEventTracker();

  useEffect(() => {
    publishEvent({
      action: Action.impression,
      cartId: cartSessionId,
      category: Category.addToBag,
      dispensaryId: menuItem.dispensaryId,
      label: menuItem.dispensary.inStoreCartEnabled
        ? `${Label.variantSelection} - shop every menu`
        : Label.variantSelection,
      menuItemId: menuItem.id,
      productId: selectedVariantId,
    });
  }, []);

  const userPrefersMedical = useSelector(getUserPrefersMedical);
  const isDualLicense = isDispensaryDualLicense(menuItem.dispensary.tags);
  const hasAlternateMenuTypeVariants = menuItem.variants.some((variant) =>
    userPrefersMedical ? !variant.medical : variant.medical,
  );
  const variants =
    isDualLicense && hasAlternateMenuTypeVariants
      ? menuItem.variants.filter(
          testVariantsForMenuType(
            userPrefersMedical ? MenuType.Med : MenuType.Rec,
          ),
        )
      : menuItem.variants;
  const sortedVariants = sortVariants(variants);

  const selectionViewStyles = (type: "variants" | "offers") =>
    classnames("text-xs font-bold rounded-full px-sm mx-xs", {
      "bg-default text-white": selectionView === type,
      "text-green": selectionView !== type,
    });

  const handleViewSelection = (view: "variants" | "offers") => {
    setSelectionView(view);
    trackEvent(
      "Quick Add to Bag",
      "Click",
      view === "variants" ? "Amount tab" : "Available offers tab",
    );
  };

  return (
    <>
      {!!menuItem?.offers?.length && menuItem.offers.length > 0 && (
        <div className="flex flex-row my-md">
          <button
            onClick={() => handleViewSelection("variants")}
            className={selectionViewStyles("variants")}
          >
            Amount
          </button>
          <button
            onClick={() => handleViewSelection("offers")}
            className={selectionViewStyles("offers")}
          >
            Available offers ({menuItem.offers.length})
          </button>
        </div>
      )}
      {selectionView === "offers" ? (
        <SmartOfferCard
          offers={menuItem.offers ?? []}
          displayList
          dealLink={`/${
            countryCode === "CA" ? "cannabis-store" : "dispensary-info"
          }/${menuItem.dispensary.slug}/menu`}
          abridged={false}
          gaCategory="Quick Add to Bag"
        />
      ) : (
        <>
          <MenuTypeSelection
            dispensaryId={menuItem.dispensary.id}
            className="pb-lg"
            isDualLicense={isDualLicense}
            onMenuTypeChange={onMenuTypeChange}
            showSelectInput={hasAlternateMenuTypeVariants}
          />

          <div className="row">
            <div className="col md:col-1/3">
              <div className="flex flex-row md:flex-col">
                {menuItem.thumbnailUrl && (
                  <div className="variant-menu-item-image">
                    <Image
                      src={menuItem.thumbnailUrl}
                      alt={`${menuItem.name} image`}
                      sizes={[150]}
                      ratio="square"
                    />
                  </div>
                )}
                <div className="font-bold ml-sm md:ml-none mt-none md:mt-xs">
                  {menuItem.name}
                </div>
              </div>
            </div>
            <div className="overflow-y-auto variant-selection-container col md:col-2/3 mt-md md:-mt-lg">
              <div className="row pt-lg pr-xs">
                {sortedVariants.map((variant) => (
                  <div className="col-1/2 mb-md" key={variant.id}>
                    <VariantTile
                      data-testid="variant-tile"
                      countryCode={countryCode}
                      onClick={() => {
                        publishEvent({
                          action: Action.click,
                          cartId: cartSessionId,
                          category: Category.addToBag,
                          dispensaryId: menuItem.dispensaryId,
                          label: menuItem.dispensary.inStoreCartEnabled
                            ? `${Label.variantSelection} - shop every menu`
                            : Label.variantSelection,
                          menuItemId: menuItem.id,
                          productId: variant.id,
                        });
                        onVariantSelect(variant.id);
                      }}
                      onKeyDown={(e) =>
                        e.key === "Enter" && onVariantSelect(variant.id)
                      }
                      selected={variant.id === selectedVariantId}
                      /**
                       * When there is only one variant, we can skip adding
                       * the `tabindex` property, as the only action to take
                       * is to add the one available variant to the bag.
                       */
                      tabIndex={sortedVariants.length > 1 ? 0 : undefined}
                      variant={variant}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="absolute bottom-0 left-0 w-full border-t border-light-grey md:w-auto p-md md:-mx-lg md:relative md:pb-none">
            <Button
              className="mx-auto"
              disabled={addingToBag}
              onClick={async () => {
                setAddingToBag(true);
                publishEvent({
                  action: Action.click,
                  cartId: cartSessionId,
                  category: Category.addToBag,
                  dispensaryId: menuItem.dispensaryId,
                  label: menuItem.dispensary.inStoreCartEnabled
                    ? `${Label.variantConfirmation} - shop every menu`
                    : Label.variantConfirmation,
                  menuItemId: menuItem.id,
                  productId: selectedVariantId,
                });

                try {
                  await onAddToBagClick();
                } catch (e) {
                  logError("Error on click", {
                    functionName: "VariantSelection",
                  });
                  setAddingToBag(false);
                }
              }}
              variant="secondary"
              iconFilePath="bag.svg"
            >
              {addingToBag ? "adding…" : "add to cart"}
            </Button>
          </div>
        </>
      )}
      <style jsx>{`
        .variant-selection-container {
          height: 320px;
        }
        @media (max-width: 768px) {
          .variant-selection-container {
            height: 100%;
            max-height: calc(100vh - 330px);
          }
          .variant-menu-item-image {
            width: 70px;
          }
        }
      `}</style>
    </>
  );
};
